import React from "react";
import styled from "styled-components";
import ShopifyImg from "../images/Shopify.png";
import RealEstate from "../images/realestate.png";
import AppointmentImg from "../images/appointment.png";
import Calc from "../images/calc.png";
import Top from "../images/topadvice.png";
import Weather from "../images/blueprint.PNG";
import Fade from "react-reveal/Fade";

function Projects() {
  return (
    <FullPageSectionWrapper id="Projects">
      <HeaderText>Projects</HeaderText>
      <ProjectWrapper>
        <Fade up>
          <ProjectCard>
            <ImgWrapper>
              <CardImg
                src={Weather}
                width={"200px"}
                width2100={"170px"}
                width1500={"140px"}
              />
            </ImgWrapper>
            <TextWrapper>
              <CardHeader>Blueprintdrops.com</CardHeader>
              <CardText>
                A website for sneaker enthusiast to use as a resource to find
                out when and where shoes will be for sale in the lower mainland.
                Sneaker collecting is very competative, when there is only 10
                pairs of shoes in stock, and there are 50 people lined up
                outside a store, it is important to have the release info so you
                can be first in line. Also includes a ecommerce page, with add to cart functionality.
              </CardText>
              <CardHeader2>Technologies Used</CardHeader2>
              <ul>
                <ListItem>Typescript</ListItem>
                <ListItem>NextJS</ListItem>
                <ListItem>ReactJS</ListItem>
                <ListItem>Styled Components</ListItem>
                <ListItem>Stripe</ListItem>
                <ListItem>Firebase</ListItem>
                <ListItem>NodeJS</ListItem>
                <ListItem>ExpressJS</ListItem>
                <ListItem>MongoDB</ListItem>
                <ListItem>Heroku</ListItem>
                <ListItem>Vercel</ListItem>
              </ul>
              <WebsiteButton href="https://blueprintdrops.com">
                Visit Site
              </WebsiteButton>
            </TextWrapper>
          </ProjectCard>
        </Fade>
      </ProjectWrapper>
    </FullPageSectionWrapper>
  );
}

export default Projects;

const FullPageSectionWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  max-width: 100%;
  background-color: white;
`;

const HeaderText = styled.p`
  color: black;
  font-family: "Roboto", sans-serif;
  text-align: left;
  font-size: 90px;
  line-height: 1.2;
  margin-left: 150px;
  margin-top: 150px;
  @media (max-width: 2100px) {
    font-size: 70px;
  }

  @media (max-width: 1255px) {
    margin-top: 100px;
    font-size: 55px;
  }

  @media (max-width: 768px) {
    text-align: center;
    margin-left: 0px;
    margin-top: 60px;
  }
`;
const ProjectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: 100px;
  width: 70%;
  margin: 70px auto;
  @media (max-width: 2100px) {
    width: 90%;
  }

  ul {
    list-style-type: circle;
    margin-left: 18px;
    text-align: left;
  }
`;

const ProjectCard = styled.div`
  width: 100%;
  background-color: #7510f7;
  margin-left: ${(props) => props.left};
  margin-right: ${(props) => props.right};
  margin-bottom: 50px;
  padding-top: 25px;
  padding-bottom: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 40px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
`;

const CardImg = styled.img`
  width: 80%;
  padding: 10px;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 45%;
  height: 80%;

  @media (max-width: 2100px) {
    width: 55%;
    height: 70%;
  }

  @media (max-width: 650px) {
    width: 90%;
    justify-content: center;
    height: 50%;
  }
`;
const CardHeader = styled.p`
  color: white;
  font-size: 32px;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
  @media (max-width: 1519px) {
    font-size: 25px;
  }
  @media (max-width: 900px) {
    margin-bottom: 15px;
  }

  @media (max-width: 650px) {
    padding-top: 20px;
    text-align: center;
    width: 100%;
  }
`;

const CardHeader2 = styled.p`
  color: white;
  font-size: 22px;
  margin-bottom: 20px;
  font-family: "Roboto", sans-serif;
  @media (max-width: 1519px) {
    font-size: 25px;
  }
  @media (max-width: 900px) {
    margin-bottom: 15px;
  }

  @media (max-width: 650px) {
    padding-top: 20px;
    text-align: center;
    width: 100%;
  }
`;

const CardText = styled.p`
  text-align: left;
  color: white;
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 1.2;
  @media (max-width: 2100px) {
    font-size: 16px;
  }

  @media (max-width: 1519px) {
    font-size: 14x;
  }
`;
const WebsiteButton = styled.a`
  background-color: #ff2253;
  color: white;
  display: inline-block;
  padding: 0.7em 1.4em;
  margin-top: 15px;
  border-radius: 3px;
  box-sizing: border-box;
  text-decoration: none;
  font-weight: 600;
  text-align: center;
  transition: all 0.2s;
  border: none;
  font-size: 18px;
  font-family: "Montserrat", sans-serif;

  &:hover {
    background-color: #d3002f;
    cursor: pointer;
  }
  @media (max-width: 2100px) {
    padding: 0.5em 1.2em;
  }
  @media (max-width: 1519px) {
    padding: 0.3em 0.7em;
  }
`;
const ImgWrapper = styled.div`
  width: 40%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
`;

const ListItem = styled.li`
  color: white;
  margin-bottom: 2px;
`;
