import React from 'react'
import styled from 'styled-components'
import aboutMeImage from '../images/aboutme.jpeg'
import githubImg from '../images/github.png'
import linkedin from '../images/linkedin.png'
import email from '../images/email.png'
import Fade from 'react-reveal/Fade';
import { keyframes } from 'styled-components'

function AboutMe() {
    return (
        <FullPageSectionWrapper id="AboutMe">
            <HeaderWrapper>
                <Fade bottom>
                    <HeaderTitle>
                        About Me
                </HeaderTitle>
                </Fade>
                <Fade delay={500} bottom>
                    <HeaderText>
                        <br /> This past year I've been working with a company called App-Scoop. I've been leading a team of developers, QA, devOps to deliver high quality scalable websites for selling NFTs using Polygon. I've learned alot about web architecture, and NFTs by leading the internal meetings for my team as well as being a Software Developer.<br/> <br/> More than just the front end, I'm asked about the entire system daily, and asked to break down features requested by our clients. For this reason I have gained alot of knowledge about each department in our company, because all communication went through me. I'm always willing to learn, I am a big believer in becoming a master of your craft. I have gained great experience and knowledge about NFTs, Opensea, blockchain and would like to continue to learn with a company in the Web3 space.

                    <SocialMediaIconsWrapper>
                            <Fade delay={800} bottom>

                                <li>
                                    <a href="https://github.com/kevinpanesar">
                                        <GithubLogo title="Check out my code!" src={githubImg} />
                                    </a>
                                </li>
                            </Fade>
                            <Fade delay={1200} bottom>
                                <li>
                                    <a href="https://linkedin.com/in/kevin-panesar">
                                        <LinkedinLogo title="Lets connect on Linkedin!" src={linkedin} />
                                    </a>
                                </li>
                            </Fade>
                            <Fade delay={1500} bottom>
                                <li>
                                    <a href="mailto: kevin_panesar@hotmail.com">
                                        <EmailLogo src={email} />
                                    </a>
                                </li>
                            </Fade>
                        </SocialMediaIconsWrapper>
                    </HeaderText>
                </Fade>
            </HeaderWrapper>
            <Fade delay={1800} bottom>
                <AboutMeImg src={aboutMeImage} />
            </Fade>

        </FullPageSectionWrapper>
    )
}

export default AboutMe

const FullPageSectionWrapper = styled.div`
    width: 100vw;
    height: 80vh;
    max-width:100%;
    background-color: white;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;


   @media (max-width: 768px) {
         height: 50vh;
  }
  
  @media (max-width: 610px) {
    flex-direction: column;
    height: auto;
  }

`

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;



`

const HeaderTitle = styled.p`
    color: ${({ theme }) => theme.text};
    font-family: 'Roboto', sans-serif;
    text-align: left;
    font-size: 90px;
    margin-left: 150px;
    line-height: 1.2;

      @media (max-width: 2100px) {
        font-size: 70px;
  }

   @media (max-width: 1255px) {
        font-size: 50px;
  }

  @media (max-width: 1080px) {
        margin-left: 120px;
        font-size: 45px;
  }

  @media (max-width: 770px) {
        margin-left: 50px;
  }

  @media (max-width: 610px) {
        text-align: center;
        margin-left: 0px;
        margin-top: 15px;
        font-size: 40px;
  }

`


const SocialMediaIconsWrapper = styled.div`
display: flex;
list-style: none;
margin-top: 15px;

a{
    margin-right: 15px;
}

   @media (max-width: 610px) {
        justify-content: center
  }

`


const HeaderText = styled.p`
    color: black;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
    font-size: 27px;
    margin-left: 150px;
    line-height: 1.4;

@media (max-width: 2100px) {
      font-size: 20px;
  }

   @media (max-width: 1255px) {
        font-size: 18px;
  }

   @media (max-width: 1080px) {
        margin-left: 120px;
         font-size: 17px;
         width: 400px;
  }

    @media (max-width: 770px) {
        margin-left: 50px;
        width: 70%;
  }

     @media (max-width: 610px) {
        margin: 0 auto;
        width: 80%;
  }
`
const AboutMeImg = styled.img`
    width: 37%;
    border: 10px solid #7510F7;
    padding: 15px;


   @media (max-width: 770px) {
       width: 150px;
       margin-right: 15px;
 }

   @media (max-width: 610px) {
        margin: 15px auto;
        width: 250px;
  }
`

const UnderLine = styled.span`
border-bottom: 3px solid #7510F7;
`
const GithubLogo = styled.img`
width: 70px;
cursor: pointer;
transition: all .2s ease-in-out;
border-radius: 50%;
&:hover{
    transform: scale(1.1);
}

 @media (max-width: 2100px) {
      width: 50px;
  }
`

const LinkedinLogo = styled(GithubLogo)``;

const EmailLogo = styled(GithubLogo)``;
